import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  OperationTypeViewModel,
  ShiftCalendarViewModel,
  WorkCenterViewModel,
  WorkCenterService,
  WorkCenterGroupViewModel,
  WarehouseLocationViewModel
} from 'chronos-basedata-client';
import { GetWorkCenters$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-centers';
import { GetWorkCenterById$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-by-id';
import { GetWorkCenterGroups$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-groups';
import { GetShiftCalendars$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-shift-calendars';
import { GetOperationTypes$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-operation-types';
import { UpdateWorkCenters$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/update-work-centers';
import { GetWorkCenterByNameOrExternalId$Params } from 'projects/chronos-basedata-client/src/lib/api/fn/work-center/get-work-center-by-name-or-external-id';
import { Quantity } from 'chronos-core-client';

export interface WorkCenterEditData {
  counterUnitId: string;
  declaredPerformance?: number | null;
  inputWarehouseLocation: WarehouseLocationViewModel;
  isActive: boolean;
  operationTypeId: number;
  outputWarehouseLocation: WarehouseLocationViewModel;
  processPerQuantity: number;
  requiresApproval: boolean;
  rowVersion: number;
  shiftCalendarId: number;
  workCenterId: number;
}

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDsService {
  constructor(private workCenterService: WorkCenterService) {}

  public getWorkCenters(searchQuery?: string, filterForActive?: boolean, filterForSite?: boolean): Observable<WorkCenterViewModel[]> {
    const params: GetWorkCenters$Params = {
      workCenterId: null,
      searchQuery,
      workCenterExternalId: null,
      filterForActive,
      filterForSite
    };

    return this.workCenterService.getWorkCenters(params);
  }

  public getWorkCenterById(workCenterId: number): Observable<WorkCenterViewModel> {
    const params: GetWorkCenterById$Params = {
      workCenterId
    };

    return this.workCenterService.getWorkCenterById(params);
  }

  public getWorkCenterByNameOrExternalId(searchQuery: string): Observable<WorkCenterViewModel> {
    const params: GetWorkCenterByNameOrExternalId$Params = {
      searchQuery
    };
    return this.workCenterService.getWorkCenterByNameOrExternalId(params);
  }

  public getWorkCenterGroups(): Observable<WorkCenterGroupViewModel[]> {
    const params: GetWorkCenterGroups$Params = {};
    return this.workCenterService.getWorkCenterGroups(params);
  }

  public getShiftCalendars(): Observable<ShiftCalendarViewModel[]> {
    const params: GetShiftCalendars$Params = {};

    return this.workCenterService.getShiftCalendars(params);
  }

  public getOperationTypes(): Observable<OperationTypeViewModel[]> {
    const params: GetOperationTypes$Params = {};

    return this.workCenterService.getOperationTypes(params);
  }

  public updateWorkCenters(entry: WorkCenterEditData[]): Observable<void> {
    const data = entry.map((item) => ({
      counterUnitId: item.counterUnitId,
      declaredPerformance: { value: item.declaredPerformance, unitId: item.counterUnitId } as Quantity,
      inputWarehouseLocationId: item.inputWarehouseLocation.id,
      isActive: item.isActive,
      operationTypeId: item.operationTypeId,
      outputWarehouseLocationId: item.outputWarehouseLocation.id,
      processPerQuantity: { value: item.processPerQuantity, unitId: item.counterUnitId } as Quantity,
      requiresApproval: item.requiresApproval,
      rowVersion: item.rowVersion,
      shiftCalendarId: item.shiftCalendarId,
      workCenterId: item.workCenterId
    }));

    const params: UpdateWorkCenters$Params = {
      body: data
    };

    return this.workCenterService.updateWorkCenters(params);
  }
}
