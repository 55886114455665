import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ManualMachineMode, OutputContainerCreationMode, WasteQuantityMode, WorkCenter, WorkCenterState } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings.model';
import { AppSettingsStore } from './app-settings.store';
import { WorkCenterProductionMode } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class AppSettingsQuery extends Query<AppSettings> {
  public workCenterState$: Observable<WorkCenterState> = this.select((state) => state.workCenterState);
  public allowQuantityChange$: Observable<boolean> = this.select((state) => state.workCenter?.allowQuantityChangeInDismount);
  public externalWorkCenterId$: Observable<string> = this.select((state) => state.workCenter.externalWorkCenterId);
  public workCenterName$: Observable<string> = this.select((state) => this.formatWorkCenterName(state.workCenter));
  public isQaHistoryEnabledForSetup$: Observable<boolean> = this.select((state) => state.isSetupQaHistoryPhaseEnabled);
  public isLineClearanceEnabledForSetup$: Observable<boolean> = this.select((state) => state.isSetupLineClearancePhaseEnabled);
  public isToolCheckoutEnable$: Observable<boolean> = this.select((state) => state.isToolCheckoutEnable);
  public isQaChecksEnabledForSetup$: Observable<boolean> = this.select((state) => state.isSetupQaChecksPhaseEnabled);
  public isPatternInfoEnabledForSetup$: Observable<boolean> = this.select((state) => state.isSetupPatternInfoPhaseEnabled);
  public workCenterProductionMode$: Observable<WorkCenterProductionMode> = this.select(
    (state) => state.workCenter.workCenterProductionMode
  );
  public isManualModeForContainer$: Observable<boolean> = this.select(
    (state) => state.workCenter.outputContainerCreationMode === OutputContainerCreationMode.Manual
  );
  public isAutomatedMode$: Observable<boolean> = this.select(
    (state) => state.workCenter.workCenterProductionMode === WorkCenterProductionMode.Automation
  );
  public manualMachineMode$: Observable<ManualMachineMode> = this.select((state) =>
    state.workCenter.workCenterProductionMode === WorkCenterProductionMode.Automation
      ? ManualMachineMode.None
      : state.workCenter.manualMachineMode
  );
  public canShowGrossQuantity$: Observable<boolean> = this.select((state) => state.workCenter.canShowGrossQuantity);
  public isQaHistoryTabEnabled$: Observable<boolean> = this.select((state) => this.isQaHistoryEnabled(state));
  public isWorkCenterSelectionEnabled$: Observable<boolean> = this.select((state) => state.features?.enableWorkCenterSelection ?? false);
  public isMachineSpeedSimulationEnabled$: Observable<boolean> = this.select(
    (state) => state.features?.enableMachineSpeedSimulation ?? false
  );
  public isApprovalReportEnabled$: Observable<boolean> = this.select((state) => state.features?.enableApprovalReport ?? false);
  public isDbResetEnabled$: Observable<boolean> = this.select((state) => state.features?.enableDbReset ?? false);
  public isClockInEnabled$: Observable<boolean> = this.select((state) => state.features?.enableClockIn ?? false);
  public isApprovalEditSetupEnabled$: Observable<boolean> = this.select((state) => state.features?.enableApprovalEditSetup ?? false);
  public isEnableLoginWithBadgeId$: Observable<boolean> = this.select((state) => state.features?.enableLoginWithBadgeId ?? false);
  public allowBillOfMaterialDuplication$: Observable<boolean> = this.select((state) => state.workCenter.allowBillOfMaterialDuplication);
  public automaticPeriodSelectionOnProductionOrderStart$: Observable<boolean> = this.select(
    (state) => state.features?.automaticPeriodSelectionOnProductionOrderStart ?? false
  );
  public isEnableResourceConfigurationReadOnly$: Observable<boolean> = this.select(
    (state) => state.features?.enableResourceConfigurationReadOnly ?? false
  );
  public isEnableResourceConfiguration$: Observable<boolean> = this.select((state) => state.features?.enableResourceConfiguration ?? false);
  public isApprovalEditProducedQuantitiesEnabled$: Observable<boolean> = this.select(
    (state) => state.features?.enableApprovalEditProducedQuantities ?? false
  );
  public isApprovalEditSetupParameterEnabled$: Observable<boolean> = this.select(
    (state) => state.features?.enableApprovalEditSetupParameter ?? false
  );
  public isApprovalChangeQuantitiesBetweenOrdersEnabled$: Observable<boolean> = this.select(
    (state) => state.features?.enableApprovalChangeQuantitiesBetweenOrders ?? false
  );
  public isProductionEnvironment$: Observable<boolean> = this.select((state) => state.features?.isProductionEnvironment ?? true);
  public producedMaterialWasteQuantityMode$: Observable<WasteQuantityMode> = this.select(
    (state) => state.workCenter.producedMaterialWasteQuantityMode
  );
  public enableQualityCheckSignature: Observable<boolean> = this.select((state) => state.workCenter.enableQualityCheckSignature);

  constructor(protected appSettingsStore: AppSettingsStore) {
    super(appSettingsStore);
  }

  public showInactiveElement(): boolean {
    return this.getValue().features?.showInactiveElements;
  }

  public isWorkCenterModeManual(): boolean {
    return this.getWorkCenter()?.workCenterProductionMode === WorkCenterProductionMode.Manual;
  }

  public getWorkCenter(): WorkCenter | null {
    return this.getValue().workCenter;
  }

  public getWorkCenterName(): string {
    return this.formatWorkCenterName(this.getWorkCenter());
  }

  public getExternalWorkCenterId(): string {
    return this.getWorkCenter()?.externalWorkCenterId ?? '';
  }

  public isTraceabilityDisplayEnabled(): boolean {
    return this.getValue().features?.enableOperationSupportMenu;
  }

  public isEmployeeRegistrationEnabled(): boolean {
    return this.getValue().features?.enableEmployeeRegistration;
  }

  public isEmployeeRegistrationMandatory(): boolean {
    return this.getWorkCenter()?.isEmployeeRegistrationMandatory ?? false;
  }

  public enforceEmployeeRegistrationLogin(): boolean {
    return this.getValue().features?.enforceEmployeeRegistrationLogin;
  }

  public enableLoginWithBadgeId(): boolean {
    return this.getValue().features?.enableLoginWithBadgeId;
  }

  private formatWorkCenterName(workCenter: WorkCenter | null): string {
    return workCenter ? `${workCenter.externalWorkCenterId} ${workCenter.name}` : '';
  }

  private isQaHistoryEnabled(state: AppSettings): boolean {
    const parameterValue = state?.qaHistoryParameter?.parameterValue;

    return parameterValue && (parameterValue === 'Setup' || parameterValue === 'Yes');
  }
}
