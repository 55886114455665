<p-table
  #dt
  [value]="workCenters$ | async"
  dataKey="id"
  [resizableColumns]="true"
  [reorderableColumns]="false"
  columnResizeMode="expand"
  [styleClass]="'work-center-table'"
>
  <!-- dataKey="workCenterId" -->
  <ng-template pTemplate="header">
    <tr class="work-center-table__header-row">
      <th
        *ngFor="let col of columns"
        [style.width]="col.width"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        [pResizableColumnDisabled]="!col.resizable"
        [class]="col.styleClass"
        pResizableColumn
      >
        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
        {{ col.header | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workCenter let-rowIndex="rowIndex">
    <tr [formGroup]="formGroup.get('rows').get(rowIndex.toString())" class="work-center-table__order-row">
      <td class="tiny-cell padding-left">
        {{ workCenter.site.externalSiteId }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.type }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.externalWorkCenterId }}
      </td>
      <td class="medium-cell">
        {{ workCenter.name }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.workCenterGroup.name }}
      </td>

      <!-- operation type list -->
      <td class="medium-cell" [ngClass]="{ required: checkValidity(rowIndex, 'operationTypeId') }">
        <ng-container *ngIf="editAll; else operationType">
          <p-dropdown
            [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
            formControlName="operationTypeId"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.OperationType)"
            [options]="operationTypeList"
            appendTo="body"
            [dropdownIcon]="operationLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
          />
        </ng-container>
        <ng-template #operationType>
          {{ workCenter.operationTypeName }}
        </ng-template>
      </td>

      <!-- process Per Quantity -->
      <td
        class="number-cell"
        [ngClass]="{ required: checkValidity(rowIndex, 'processPerQuantity', WORKCENTER_COLUMN_TYPE.ProcessPerQuantity) }"
      >
        <ng-container *ngIf="editAll; else viewProcessPerQuantity">
          <lib-input-number
            [size]="5"
            [format]="true"
            [min]="INPUT_MIN_NUMBER"
            [max]="INPUT_MAX_NUMBER"
            formControlName="processPerQuantity"
            (onBlur)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.ProcessPerQuantity)"
          ></lib-input-number>
        </ng-container>
        <ng-template #viewProcessPerQuantity>
          {{ workCenter.processPerQuantity | formatQuantity : false }}
        </ng-template>
      </td>

      <!-- declared performance -->
      <td
        class="number-cell"
        [ngClass]="{ required: checkValidity(rowIndex, 'declaredPerformance', WORKCENTER_COLUMN_TYPE.DeclaredPerformance) }"
      >
        <ng-container *ngIf="editAll; else viewDeclaredPerformance">
          <lib-input-number
            [size]="5"
            [format]="true"
            [min]="INPUT_MIN_NUMBER"
            [max]="INPUT_MAX_NUMBER"
            formControlName="declaredPerformance"
            (onBlur)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.DeclaredPerformance)"
          ></lib-input-number>
        </ng-container>
        <ng-template #viewDeclaredPerformance>
          {{ workCenter.declaredPerformance | formatQuantity : false }}
        </ng-template>
      </td>

      <!-- counter unit -->
      <td class="medium-cell" [ngClass]="{ required: checkValidity(rowIndex, 'counterUnitId') }">
        <ng-container *ngIf="editAll; else viewCounterUnit">
          <input
            pInputText
            type="text"
            formControlName="counterUnitId"
            (onBlur)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.CounterUnit)"
            required
            class="inputtextbox"
          />
        </ng-container>
        <ng-template #viewCounterUnit>
          {{ workCenter.counterUnitId }}
        </ng-template>
      </td>

      <!-- input Warehouse LocationId -->
      <td class="medium-cell">
        <div class="edit-button-alignment">
          <span class="variable-text">
            {{ workCenter.inputWarehouseLocation.warehouse.externalWarehouseId }} /
            {{ workCenter.inputWarehouseLocation.externalWarehouseLocationId }}
          </span>
          <ng-container *ngIf="editAll">
            <span class="icon-style">
              <lib-icon-button
                [iconStyleClass]="'icon-style'"
                [iconClass]="'icon-edit'"
                (clicked)="changeInputWarehouseLocation(rowIndex, workCenter)"
              ></lib-icon-button>
            </span>
          </ng-container>
        </div>
      </td>

      <!-- output Warehouse LocationId -->
      <td class="medium-cell">
        <div class="edit-button-alignment">
          <span class="variable-text">
            {{ workCenter.outputWarehouseLocation.warehouse.externalWarehouseId }} /
            {{ workCenter.outputWarehouseLocation.externalWarehouseLocationId }}
          </span>
          <ng-container *ngIf="editAll">
            <span class="icon-style">
              <lib-icon-button
                [iconStyleClass]="'icon-style'"
                [iconClass]="'icon-edit'"
                (clicked)="changeOutputWarehouseLocation(rowIndex, workCenter)"
              ></lib-icon-button>
            </span>
          </ng-container>
        </div>
      </td>
      <!-- shift calendar list -->
      <td class="medium-cell">
        <ng-container *ngIf="editAll; else shiftCalendar">
          <p-dropdown
            [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
            [options]="shiftCalendarList"
            appendTo="body"
            formControlName="shiftCalendarId"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.ShiftCalendarExternal)"
          />
        </ng-container>
        <ng-template #shiftCalendar>
          {{ workCenter.shiftCalendarExternalId }}
        </ng-template>
      </td>

      <!-- does the workcenter needs the approval? -->
      <td class="tiny-cell">
        <lib-input-switch formControlName="requiresApproval"></lib-input-switch>
      </td>

      <!-- workcenter is active  -->
      <td class="tiny-cell">
        <lib-input-switch formControlName="isActive"></lib-input-switch>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
