import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, concat, from, Observable } from 'rxjs';
import { delayWhen, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { applicationPaths, queryParameterNames } from './api-authorization.constants';
import { ApiAuthorizationConfig } from './api-authorization.config';
import { BASE_URL } from '../baseUrl';
import { Router } from '@angular/router';
import { ApmService } from '@elastic/apm-rum-angular';
import { Log, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export type IAuthenticationResult = SuccessAuthenticationResult | FailureAuthenticationResult | RedirectAuthenticationResult;

export interface SuccessAuthenticationResult {
  status: AuthenticationResultStatus.Success;
  state: any;
}

export interface FailureAuthenticationResult {
  status: AuthenticationResultStatus.Fail;
  message: string;
}

export interface RedirectAuthenticationResult {
  status: AuthenticationResultStatus.Redirect;
}

export enum AuthenticationResultStatus {
  Success,
  Redirect,
  Fail
}

export interface IUser {
  sub: string;
  name?: string;
  email?: string;
  username?: string;
}

export type LoginHook = () => Promise<any>;

export const AUTHORIZE_LOGIN_HOOKS = new InjectionToken<LoginHook[]>('AUTHORIZE_LOGIN_HOOKS');

@Injectable()
export class AuthorizeService {
  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.

  private popUpDisabled = true;
  private userManager: UserManager;
  private userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject(null);

  constructor(
    private config: ApiAuthorizationConfig,
    private injector: Injector,
    private router: Router,
    private apmService: ApmService,
    @Inject(BASE_URL) private baseUrl: string
  ) {
    if (!this.baseUrl.endsWith('/')) {
      this.baseUrl += '/';
    }
    this.userSubject.subscribe((user) => {
      const apm = apmService.apm;
      if (apm && apm.isActive()) {
        apm.setUserContext(
          user
            ? {
                id: user.sub,
                username: user.username,
                email: user.email
              }
            : {}
        );
      }
    });
  }

  private executeLoginHooks(): Promise<any> {
    const loginHooks = this.injector.get(AUTHORIZE_LOGIN_HOOKS, undefined, { optional: true });
    if (!loginHooks || !loginHooks.length) {
      return Promise.resolve();
    }
    return Promise.all(loginHooks.map((hook) => hook()));
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getUser().pipe(map((u) => !!u));
  }

  public getUser(): Observable<IUser | null> {
    return concat(
      this.userSubject.pipe(
        take(1),
        filter((u) => !!u)
      ),
      this.getUserFromStorage().pipe(
        filter((u) => !!u),
        tap((u) => {
          this.userSubject.next(u);
        }),
        delayWhen(() => from(this.executeLoginHooks()))
      ),
      this.userSubject.asObservable()
    );
  }

  public getAccessToken(): Observable<string> {
    return from(this.ensureUserManagerInitialized()).pipe(
      mergeMap(() => from(this.userManager.getUser())),
      map((user) => user && user.access_token)
    );
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  public async signIn(state: any): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    try {
      return await this.signInSilent(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to interactive methods
      console.info('Silent authentication error: ', silentError);

      return this.signInInteractively(state);
    }
  }

  private async signInSilent(state: any): Promise<IAuthenticationResult> {
    const user = await this.userManager.signinSilent(this.createArguments());
    this.userSubject.next(user.profile);
    await this.executeLoginHooks();
    return this.success(state);
  }

  private async signInInteractively(state: any): Promise<IAuthenticationResult> {
    if (this.popUpDisabled) {
      return await this.signInViaRedirect(state);
    }

    try {
      return await this.signInViaPopup(state);
    } catch (popupError) {
      console.error('Popup authentication error: ', popupError);

      // PopUps might be blocked by the user, fallback to redirect
      return this.signInViaRedirect(state);
    }
  }

  private async signInViaPopup(state: any): Promise<IAuthenticationResult> {
    try {
      const user = await this.userManager.signinPopup(this.createArguments());
      this.userSubject.next(user.profile);
      await this.executeLoginHooks();
      return this.success(state);
    } catch (popupError) {
      if (popupError.message === 'Popup window closed') {
        // The user explicitly cancelled the login action by closing an opened popup.
        return this.error('The user closed the window.');
      } else if (!this.popUpDisabled) {
        console.error('Popup authentication error: ', popupError);
      }
      throw popupError;
    }
  }

  private async signInViaRedirect(state: any): Promise<IAuthenticationResult> {
    try {
      await this.userManager.signinRedirect(this.createArguments(state));
      return this.redirect();
    } catch (redirectError) {
      console.error('Redirect authentication error: ', redirectError);
      return this.error(redirectError);
    }
  }

  public async completeSignIn(url: string): Promise<IAuthenticationResult> {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager.signinCallback(url);
      this.userSubject.next(user && user.profile);
      await this.executeLoginHooks();
      return this.success(user && user.state);
    } catch (error) {
      console.error('There was an error signing in: ', error);
      return this.error(`There was an error signing in: ${error?.error_description}`);
    }
  }

  public async signOut(state: any): Promise<IAuthenticationResult> {
    if (this.popUpDisabled) {
      return await this.signOutViaRedirect(state);
    }
    try {
      return await this.signoutViaPopup(state);
    } catch (popupSignOutError) {
      console.error('Popup signout error: ', popupSignOutError);
      return this.signOutViaRedirect(state);
    }
  }

  private async signoutViaPopup(state: any): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    await this.userManager.signoutPopup(this.createArguments());
    this.userSubject.next(null);
    return this.success(state);
  }

  private async signOutViaRedirect(state: any): Promise<IAuthenticationResult> {
    try {
      await this.userManager.signoutRedirect(this.createArguments(state));
      return this.redirect();
    } catch (redirectSignOutError) {
      console.error('Redirect signout error: ', redirectSignOutError);
      return this.error(redirectSignOutError);
    }
  }

  public async completeSignOut(url: string): Promise<IAuthenticationResult> {
    await this.ensureUserManagerInitialized();
    try {
      const state = await this.userManager.signoutCallback(url);
      this.userSubject.next(null);
      return this.success(state && state.state);
    } catch (error) {
      console.error(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  private createArguments(state?: any): any {
    return { useReplaceToNavigate: true, data: state };
  }

  private error(message: string): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  private success(state: any): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  private redirect(): IAuthenticationResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  private async ensureUserManagerInitialized(): Promise<void> {
    if (this.userManager !== undefined) {
      return;
    }

    // The oidc client library logs in to the OpenID Connect provider. This sets a session cookie that is used later to
    // authenticate the user.
    // The cookie is set using the SameSite=Lax policy. This means that the cookie is not set when http is used. In this
    // case the logout callback is called - ending the session.

    // Uncomment the following lines for debugging of the OIDC client
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);

    const settings: UserManagerSettings = {
      authority: this.config.authority,
      client_id: this.config.clientId,
      redirect_uri: `${this.baseUrl}${applicationPaths.LoginCallback}`,
      silent_redirect_uri: `${this.baseUrl}assets/auth/silent-callback.html`,
      post_logout_redirect_uri: `${this.baseUrl}${applicationPaths.LogOutCallback}`,
      response_type: this.config.responseType,
      scope: this.config.scope,
      automaticSilentRenew: true,
      checkSessionIntervalInSeconds: 2,
      includeIdTokenInSilentRenew: true,
      loadUserInfo: true,
      monitorSession: true
    };
    this.userManager = new UserManager(settings);

    // Note: for local development, comment out this handler
    this.userManager.events.addUserSignedOut(async () => {
      console.info('Authorize: User signed out');
      await this.onLogout();
    });

    this.userManager.events.addAccessTokenExpired(async () => {
      console.info('Authorize: Access token expired');
      await this.onLogout();
    });

    this.userManager.events.addSilentRenewError(async (error: Error) => {
      console.info('Authorize: Silent renewal error:', error);
      await this.onLogout();
    });
  }

  private async onLogout(): Promise<void> {
    await this.userManager.removeUser();
    this.userSubject.next(null);
    this.router.navigate(applicationPaths.LoggedOutPathComponents);
  }

  private getUserFromStorage(): Observable<IUser> {
    return from(this.ensureUserManagerInitialized()).pipe(
      mergeMap(() => this.userManager.getUser()),
      map((u) => (u && !u.expired && u.profile ? u.profile : null))
    );
  }
}
